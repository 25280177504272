import { createSelector } from '@reduxjs/toolkit';

import { ListState } from '../../app/models';

export interface HistoryState {
  history: ListState,
}

const selectSelf = (state: HistoryState) => state;

export const getHistoryListInfo = createSelector(
  selectSelf, (state: HistoryState) => state.history
);

export const getSelectedHistoryItemsList = createSelector(
  selectSelf, (state: HistoryState) => state.history.selected
);

export const getAllHistoryItemsList = createSelector(
  selectSelf, (state: HistoryState) => state.history.items
);

export const getHistoryPagination = createSelector(
  selectSelf, (state: HistoryState) => state.history.pagination
);
