import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { History, LocationState } from 'history';
import LoadingBar from 'react-redux-loading-bar';

import { ApplyInterceptors } from '../../../../axios/axios-interceptors';

export const WithAxiosInterceptors = (Wrapped: FC, strict = true) => (): JSX.Element => {
  const history: History<LocationState> = useHistory();
  ApplyInterceptors(history, strict);

  return <>
    <LoadingBar style={{ backgroundColor: 'blue', height: '5px' }} />
    <Wrapped />
  </>;
};

export default WithAxiosInterceptors;
