import React, { FC, MutableRefObject, useRef } from 'react';

export interface DropdownTogglerProps {
  toggleList?: () => void;
  toggled?: boolean;
  value?: string;
  children?: string;
  setPos?: (pos: DOMRect | undefined) => void;
}

export const DropdownToggler: FC<DropdownTogglerProps> = (props: DropdownTogglerProps): JSX.Element => {
  const {
    toggleList,
    toggled,
    value,
    children,
    setPos,
  } = props;
  const ref: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  // возможно модифицировать таггл лист и высылать туда кординаты кнопки на момент нажатия

  const toggleClick = (): void => {
    if (toggleList !== undefined && setPos !== undefined) {
      setPos(ref.current?.getBoundingClientRect());
      toggleList();
    }
  };

  return (
    <div className={ `dropdown-toggle-button` } ref={ ref }>
      <button className={ `dropdown-toggle-button__element${ toggled ? ' toggled' : '' }` }
              onClick={ toggleClick }>
        { value || children || '' }
      </button>
    </div>
  );
};

export default DropdownToggler;
