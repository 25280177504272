import { AnyAction, Draft } from '@reduxjs/toolkit';

import { FileState } from '../../app/models';

import {
  UPATE_FILE,
  REMOVE_FILE,
  UPDATE_SHORT_URL,
  FILE_INFO_UPDATE,
} from '../constants';

const defaultState: FileState = {
  file: {
    name: '',
    createdAt: '',
    ext: '',
    size: 0,
    hash: '',
    height: 0,
    thumb: '',
    shortUrl: {
      url: '',
    },
    width: 0,
    viewCount: 0
  },
  folder: {
    name: '',
    hash: '',
    public: false,
    createdAt: '',
    files: 0
  },
  owner: {
    name: '',
    id: 0,
    email: '',
  },
  previous: '',
  next: '',
  exists: true,
};

const fileStore: FileState = defaultState;

export const file = (state: Draft<FileState> = fileStore, action: AnyAction): FileState => {
  switch (action.type) {
    case `${ FILE_INFO_UPDATE }_FULFILLED`:
      return {
        ...(state as FileState),
        file: action.payload.file,
        folder: action.payload.folder,
        owner: action.payload.user,
        previous: action.payload?.previous,
        next: action.payload?.next,
        exists: true,
      };
    case `${ FILE_INFO_UPDATE }_REJECTED`:
      return { ...defaultState, exists: false };
    case `${ UPATE_FILE }_FULFILLED`:
      return {
        ...(state as FileState),
        file: action.payload.file,
        folder: action.payload.folder,
        owner: action.payload.user,
        previous: action.payload?.previous,
        next: action.payload?.next,
        exists: true,
      };
    case `${ UPDATE_SHORT_URL }_FULFILLED`:
      return {
        ...(state as FileState),
        file: {
          ...(state as FileState).file,
          ...action.payload,
        },
        exists: true,
      };
    case REMOVE_FILE:
      return { ...defaultState };
    default:
      return state;
  }
};
