import React, { FC, ReactElement, useEffect, useState } from 'react';
import Modal, { Styles } from 'react-modal';

interface ModalContainerProps extends Modal.Props {
  title?: string;
  children?: ReactElement;
  customStyles?: Styles;
  key?: string;
  closeModal?: (key: string) => void;
}

const styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    backgroundColor: 'rgba(26, 26, 28, 1)',
    border: 'none',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
};

export const ModalContainer: FC<ModalContainerProps> = (props: ModalContainerProps): JSX.Element => {
  const { key, title, children, closeModal, isOpen, customStyles } = props;
  const [ modalIsOpen, setIsOpen ] = useState(isOpen);
  const style = {
    ...styles,
    ...customStyles
  };

  const close = (): void => {
    if (closeModal !== undefined) {
      closeModal(key || '');
    }
  };

  useEffect((): void => {
    setIsOpen(isOpen);
  }, [ isOpen ]);

  return (
    <Modal { ...props }
           style={ style }
           isOpen={ modalIsOpen }
           shouldCloseOnOverlayClick={ false }
           onRequestClose={ close }
    >
      <div className="modal-container">
        <div className="modal-container-header">
          <h4 className="modal-container-header__title">
            { title }
          </h4>
          <button className="modal-container-header__close-button"
                  onClick={ close } />
        </div>
        <div className="modal-container-content">
          { children }
        </div>
      </div>
    </Modal>
  );
};

export default ModalContainer;
