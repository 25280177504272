import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

// Components
const List = lazy(() => import('../../pages/List/List'));
const HistoryPage = lazy(() => import('../../pages/HistoryPage/HistoryPage'));
const File = lazy(() => import('../../pages/File/File'));
const Login = lazy(() => import('../../pages/Sections/Auth/Login/Login'));
const UpdateEmail = lazy(() => import('../../pages/Sections/Auth/UpdateEmail/UpdateEmail'));
const Registration = lazy(() => import('../../pages/Sections/Auth/Registration/Registration'));
const ForgotPassword = lazy(() => import('../../pages/Sections/Auth/ForgotPassword/ForgotPassword'));
const UpdatePassword = lazy(() => import('../../pages/Sections/Auth/UpdatePassword/UpdatePassword'));
const Direct = lazy(() => import('../../pages/Direct/Direct'));
const FileNotFound = lazy(() => import('../../pages/FileNotFound/FileNotFound'));

// HOC
import { AuthRequire, WithAxiosInterceptors } from '../../shared';

import { routes } from '../../../routes';

export const ScreenshotMaker: FC = (): JSX.Element => {
  const loading: JSX.Element = <div className="suspense-block">{ '' }</div>;
  return (
    <Suspense fallback={ loading }>
      <BrowserRouter>
        <Switch>
          <Route path={ routes.login } component={ AuthRequire(Login, false) } />
          <Route path={ routes.registration } component={ AuthRequire(Registration, false) } />
          <Route path={ routes.forgotPassword } component={ AuthRequire(ForgotPassword, false) } />
          <Route path={ `${ routes.newPassword }/:token` } component={ WithAxiosInterceptors(UpdatePassword) } />
          <Route path={ `${ routes.newEmail }/:token` } component={ WithAxiosInterceptors(UpdateEmail) } />
          <Route path={ `${ routes.list }/:folderHash` } component={ AuthRequire(List) } />
          <Route path={ routes.list } component={ AuthRequire(List) } />
          <Route path={ `${ routes.file }/:fileHash` } component={ WithAxiosInterceptors(File, false) } />
          <Route path={ `${ routes.direct }/:fileHash` } component={ WithAxiosInterceptors(Direct, false) } />
          <Route path={ routes.history } component={ AuthRequire(HistoryPage) } />
          <Route path={ routes.fileNotFound } component={ WithAxiosInterceptors(FileNotFound, false) } />
          <Route><Redirect to={ routes.list } /></Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default ScreenshotMaker;
