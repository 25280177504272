import { AxiosError } from 'axios';

import {
  Response,
  ChangeEmailRequest,
  ChangePasswordRequest,
} from '../../app/models';

import {
  changeEmail,
  resetPassword,
  changeEmailRequest,
  resetPasswordRequest,
} from '../../request-agent';

export const resetPasswordRequestThunk = (email: string) => async (): Promise<boolean> => {
  try {
    const response = await resetPasswordRequest(email);
    return response.status === 200;
  }
  catch (e: unknown) {
    console.error(e);
    throw e as AxiosError<Response<void>>;
  }
};

export const resetPasswordThunk = (form: ChangePasswordRequest) => async (): Promise<boolean> => {
  try {
    const response = await resetPassword(form);
    return response.status === 200;
  }
  catch (e: unknown) {
    console.error(e);
    throw e as AxiosError<Response<void>>;
  }
};

export const changeEmailRequestThunk = () => async (): Promise<boolean> => {
  try {
    const response = await changeEmailRequest();
    return response.status === 200;
  }
  catch (e: unknown) {
    console.error(e);
    throw e as AxiosError<Response<void>>;
  }
};

export const changeEmailThunk = (form: ChangeEmailRequest) => async (): Promise<boolean> => {
  try {
    const response = await changeEmail(form);
    return response.status === 200;
  }
  catch (e: unknown) {
    console.error(e);
    throw e as AxiosError<Response<void>>;
  }
};
