export const routes = {
    login: '/login',
    registration: '/registration',
    forgotPassword: '/forgot-password',
    newPassword: '/update-password',
    newEmail: '/update-email',
    list: '/list',
    file: '/file',
    direct: '/direct',
    history: '/history',
    fileNotFound: '/error/file-not-found'
};

export default routes;
