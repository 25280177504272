import { ListState, ListItem } from '../../app/models';

export const stateGetAllItemsHashes = (state: ListState): Array<string> => {
  return state.items.map((item: ListItem) => item.hash);
};

export const stateToggleItem = (state: ListState, folderHash: string): Array<string> => {
  const result: Array<string> = [];

  if (state.selected.includes(folderHash)) {
    result.push(...state.selected.filter((item: string): boolean => item !== folderHash));
  } else {
    result.push(...state.selected, folderHash);
  }

  return result;
};
