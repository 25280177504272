import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { applyMiddleware, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { logger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { Store } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { App } from './App';
import reducer from './redux/reducer';

import '../src/styles/styles.scss';
import { ApplyAxiousDefaults } from './axios';

import { loadingBarMiddleware } from 'react-redux-loading-bar';

const middlewares = [
  thunkMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const store: Store = createStore(reducer, applyMiddleware(...middlewares));

ApplyAxiousDefaults();
Modal.setAppElement('#modal');

Sentry.init({
  dsn: "https://28fe215b23c6461a9bbbe80b4213e3e6@o1025135.ingest.sentry.io/5991181",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={ store }>
    <React.StrictMode>
      <App />
      <ToastContainer pauseOnFocusLoss={ false } />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
