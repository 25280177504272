import React, { FC } from 'react';

export enum ButtonType {
  Regular,
  Transparent,
  Yellow,
  ListButton,
  Sidebar,
  Gray,
  Red,
}

export interface ButtonProps {
  value?: string;
  children?: string;
  submit?: boolean;
  onClick?: () => void;
  className?: string;
  buttonType?: ButtonType;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const {
    value,
    className,
    submit,
    buttonType,
    disabled,
    onClick,
    children,
  } = props;

  const buttonClass = (): string => {
    let buttonTypeClass: string;

    switch (buttonType) {
      case ButtonType.Regular:
        buttonTypeClass = 'button__element_blue';
        break;
      case ButtonType.Transparent:
        buttonTypeClass = 'button__element_transparent';
        break;
      case ButtonType.Yellow:
        buttonTypeClass = 'button__element_yellow';
        break;
      case ButtonType.ListButton:
        buttonTypeClass = 'button__element_list';
        break;
      case ButtonType.Sidebar:
        buttonTypeClass = 'button__element_sidebar';
        break;
      case ButtonType.Gray:
        buttonTypeClass = 'button__element_gray';
        break;
      case ButtonType.Red:
        buttonTypeClass = 'button__element_red';
        break;
      default:
        buttonTypeClass = 'button__element_blue';
        break;
    }

    return buttonTypeClass;
  };

  return (
    <div className={ `button${ className ? ' ' + className : '' }` }>
      <input type={ submit ? "submit" : "button" }
             className={ `button__element ${ buttonClass() }` }
             onClick={ onClick }
             disabled={ disabled }
             value={ value || children || '' } />
    </div>
  );
};

export default Button;
