import { AnyAction, Draft } from '@reduxjs/toolkit';

// Constants
import {
  RESET_USER,
  UPDATE_USER,
  CHANGE_USER,
} from '../constants';

// Models
import { UserShort } from '../../app/models';

export interface UserState extends UserShort {
  loaded: boolean;
}

const defaultState: UserState = {
  id: 0,
  name: '',
  email: '',
  loaded: false,
};
const userInfoObject: UserState = defaultState;

export const userInfo = (state: Draft<UserState> = userInfoObject, action: AnyAction): UserState => {
  switch (action.type) {
    case 'CHANGE_USER_EMAIL':
      return Object.assign({}, state, {
        email: action.payload,
      });
    case UPDATE_USER:
      return {
        ...(state as UserShort),
        ...action?.payload,
        loaded: true,
      };
    case `${ CHANGE_USER }_FULFILLED`:
      return {
        ...(state as UserShort),
        ...action?.payload,
        loaded: true,
      };
    case `${ CHANGE_USER }_REJECTED`:
      return { id: 0, name: 'guest', email: '', loaded: true };
      case RESET_USER:
        return { ...defaultState };
    default:
      return state;
  }
};

export default userInfo;
