import Joi from 'joi';

export const useStrictNameSchema = (name: string): Joi.StringSchema => {
  return Joi.string()
    .required()
    .max(50)
    .message(`${ name } should be at less than 50 characters`)
    .regex(/[$&+,:;=?@#"`~№|'<>.^*()%!]/, { invert: true })
    .message(`${ name } cannot contain special characters`)
    .regex(/^\s*$/, { invert: true })
    .message(`${ name } cannot be whitespace only`)
    .messages({
      'string.empty': `${ name } cannot be empty`,
    });
};

export default useStrictNameSchema;
