interface Size {
  x: number;
  y: number;
}

export const useProportions = (): (inner: Size, outer: Size) => Size => {
  return (inner: Size, outer: Size): Size => {
    const result: Size = inner;
    if (inner.x <= outer.x && inner.y <= outer.y) return result;

    const percFromHeight: number = 100 - (((outer.y / inner.y) * 100) * 100) / 100;
    const percFromWidth: number = 100 - (((outer.x / inner.x) * 100) * 100) / 100;

    if (percFromWidth > percFromHeight) {
      result.x = outer.x;
      result.y = inner.y - (inner.y / 100 * percFromWidth);
    } else {
      result.y = outer.y;
      result.x = inner.x - (inner.x / 100 * percFromHeight);
    }

    return result;
  };
};

export default useProportions;
