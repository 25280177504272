import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { userInfo, list, folders, history, file } from './reducers';

export const reducer = combineReducers({
  loadingBar: loadingBarReducer,
  userInfo,
  list,
  file,
  folders,
  history,
});

export default reducer;
