import { createSelector } from '@reduxjs/toolkit';

// Models
import { UserState } from '../reducers';


export interface SelectorUserState {
  userInfo: UserState,
}

const selectSelf = (state: SelectorUserState) => state;

export const getUserInfo = createSelector(
  selectSelf, (state: SelectorUserState) => state.userInfo
);
