import React, { FC, Suspense } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { History, LocationState } from 'history';
import LoadingBar from 'react-redux-loading-bar';

import { ApplyInterceptors } from '../../../axios/axios-interceptors';

import { useQuery } from '../../hooks';

import routes from '../../../routes';

export const AuthRequire = (Wrapped: FC, authRequired = true) => (): JSX.Element => {
  const queries: URLSearchParams = useQuery();
  const history: History<LocationState> = useHistory();
  ApplyInterceptors(history);
  const loading = <div className="suspense-block">{ '' }</div>;

  if (authRequired) {
    if (!localStorage.getItem('token')) {
      return <Redirect to={ `${ routes.login }?${ queries.toString() }` } />;
    }
  } else {
    if (localStorage.getItem('token')) {
      return <Redirect to={ `${ routes.list }?${ queries.toString() }` } />;
    }
  }

  return <Suspense fallback={ loading }>
    <LoadingBar style={{ backgroundColor: 'blue', height: '5px' }} />
    <Wrapped />
  </Suspense>;
};

export default AuthRequire;
