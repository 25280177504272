import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { joiResolver } from '@hookform/resolvers/joi';
import { AxiosError } from 'axios';
import Joi from 'joi';

// Components
import { Button, FormTextInput } from '../..';

// Hooks
import { useFormErrors, useStrictNameSchema } from '../../../../hooks';

// Models
import { ChangeFolderRequest, Response } from '../../../../models';

// Redux
import { createNewFolderAction } from '../../../../../redux';

interface NewFolderFormProps {
  formDone?: () => void;
}

export const NewFolderForm: FC<NewFolderFormProps> = (props: NewFolderFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const { formDone } = props;
  const strictNameSchema = useStrictNameSchema('Folder name');
  const formScheme: Joi.ObjectSchema<ChangeFolderRequest> = Joi.object({
    name: strictNameSchema,
  });

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ChangeFolderRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(formScheme),
    defaultValues: {
      name: '',
    },
  });
  const setFormErrors = useFormErrors(formScheme, setError);

  const onSubmit = async (): Promise<void> => {
    try {
      dispatch(createNewFolderAction(getValues('name')));

      if (formDone !== undefined) {
        formDone();
      }
    } catch (error: unknown) {
      const err: AxiosError<Response<void>> = error as AxiosError<Response<void>>;

      if (err?.response?.data?.errors) {
        setFormErrors(err.response.data.errors);
      }

      console.error(error);
    }
  };

  return (
    <form onSubmit={ handleSubmit(onSubmit) }
          className="new-folder-form"
    >
      <FormTextInput type="text"
                     autoFocus
                     register={ register('name') }
                     placeholder="Folder name"
                     showError={ !!errors?.name }
                     error={ errors.name?.message }
      />
      <Button submit
              className="new-folder-form__send"
              value="Create folder"
              disabled={ !isValid || isSubmitting } />
    </form>
  );
};

export default NewFolderForm;
