export const saveDownloadBlobData = (data: Blob, fileName: string): void => {
  const linkElement: HTMLAnchorElement = document.createElement('a');
  const url: string = window.URL.createObjectURL(data);

  linkElement.href = url;
  linkElement.download = fileName;
  linkElement.click();

  setTimeout((): void => {
    window.URL.revokeObjectURL(url);
  }, 0);
};

export const base64ToBlob = async (url: string): Promise<Blob> => {
  const res: Response = await fetch(url);
  return  await res.blob();
};

export const linkToDataURL = (url: string, callback: (base64: string | ArrayBuffer | null ) => void): void => {
  const xhr: XMLHttpRequest = new XMLHttpRequest();
  xhr.onload = function (): void {
    const reader: FileReader = new FileReader();
    reader.onloadend = (): void => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export const downloadFileByLink = (link: string, fileName: string): void => {
  linkToDataURL(link, async (data): Promise<void> => {
    if (!data) return;

    saveDownloadBlobData(await base64ToBlob(data as string), fileName);
  });
};
