import React, { FC, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import {
  Button,
  ButtonType,
  NewFolderForm,
  ModalContainer,
} from '..';

import { routes } from '../../../../routes';

interface SidebarProps {
  toggleMode: () => void;
  children?: Array<ReactElement>;
}

export const Sidebar: FC<SidebarProps> = (props: SidebarProps): JSX.Element => {
  const { toggleMode, children } = props;
  const [ opened, setOpened ] = useState('');

  const closeModal = (key: string): void => {
    if (opened.includes(key)) {
      setOpened('');
    }
  };

  return (
    <aside className={ `page-sidebar` }>
      <div className="page-sidebar-top">
        <h4 className="page-sidebar-top__title">My folders</h4>
        <Button value="New folder"
                buttonType={ ButtonType.Transparent }
                onClick={ () => setOpened('newFolder') } />
      </div>
      <div className="page-sidebar-folders">
        { children }
      </div>
      <div className="page-sidebar-bottom">
        <Link className="page-sidebar-bottom__item page-sidebar-bottom__history"
              to={ routes.history }
        >
          Browsing history
        </Link>
        <button className="page-sidebar-bottom__item page-sidebar-bottom__settings"
                onClick={ toggleMode }
        >
          Settings
        </button>
      </div>
      <ModalContainer isOpen={ opened === 'newFolder' }
                      closeModal={ closeModal }
                      title="Create new folder">
        <NewFolderForm formDone={ (): void => closeModal('newFolder') } />
      </ModalContainer>
    </aside>
  );
};

export default Sidebar;
