import { AnyAction, Draft } from '@reduxjs/toolkit';

import { ListState } from '../../app/models';

import {
  TOGGLE_HISTORY_ITEM,
  UPDATE_HISTORY_LIST,
  CLEAR_ALL_HISTORY_ITEMS,
  SELECT_ALL_HISTORY_ITEMS
} from '../constants';

import { stateGetAllItemsHashes, stateToggleItem } from './reducer-methods';

const listObject: ListState = {
  selected: [],
  items: [],
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 100,
    totalPages: 10,
  },
  loaded: false,
};

export const history = (state: Draft<ListState> = listObject, action: AnyAction): ListState => {
  switch (action.type) {
    case SELECT_ALL_HISTORY_ITEMS:
      return {
        ...(state as ListState),
        selected: stateGetAllItemsHashes(state as ListState),
      };
    case CLEAR_ALL_HISTORY_ITEMS:
      return {
        ...(state as ListState),
        selected: [],
      };
    case TOGGLE_HISTORY_ITEM:
      return {
        ...(state as ListState),
        selected: stateToggleItem(state as ListState, action.payload),
      };
    case `${ UPDATE_HISTORY_LIST }_FULFILLED`:
      return {
        ...(state as ListState),
        selected: action.payload?.selected || [],
        items: action.payload?.files || [],
        pagination: {
          ...(state as ListState)?.pagination,
          ...action.payload?.pagination,
        },
        loaded: true,
      };
    default:
      return state;
  }
};
