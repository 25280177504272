import { CSSProperties, useEffect, useState } from 'react';

interface ImageStyle {
  thumbnail: CSSProperties;
  fullSize: CSSProperties;
}

interface ImageOnLoadType {
  handleImageOnLoad: () => void;
  css: ImageStyle;
}

export const useImageOnLoad = (key: string): ImageOnLoadType => {
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);

  // Triggered when full image will be loaded.
  const handleImageOnLoad = (): void => {
    setIsLoaded(true);
  };

  useEffect((): void => {
    setIsLoaded(false);
  }, [ key ]);

  const css: ImageStyle = {
    // Thumbnail style.
    thumbnail: {
      display: isLoaded ? 'none' : 'block',
      // filter: 'blur(8px)',
    },
    // Full image style.
    fullSize: {
      display: !isLoaded ? 'none' : 'block',
    },
  };

  return { handleImageOnLoad, css };
};

export default useImageOnLoad;
