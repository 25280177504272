import React, { FC } from 'react';

import { Button, ButtonType } from '../Button';

interface RemoveConfirmDialogProps {
  confirmTitle?: string;
  denyTitle?: string;
  confirmed: (() => void) | (() => Promise<void>);
  denyed: () => void;
  children?: string;
  description?: string;
  confirmType?: ButtonType;
}

export const RemoveConfirmDialog: FC<RemoveConfirmDialogProps> = (props: RemoveConfirmDialogProps): JSX.Element => {
  const {
    confirmTitle,
    denyTitle,
    confirmed,
    denyed,
    children,
    description,
    confirmType,
  } = props;

  return (
    <div className="remove-confirm-dialog">
      <h4 className="remove-confirm-dialog__description">
        { children || description || '' }
      </h4>
      <div className="remove-confirm-dialog-buttons">
        <Button value={ denyTitle }
                onClick={ denyed }
                buttonType={ ButtonType.Gray } />
        <Button value={ confirmTitle }
                onClick={ confirmed }
                buttonType={ confirmType || ButtonType.Regular } />
      </div>
    </div>
  );
};

export default RemoveConfirmDialog;
