import axios, { AxiosResponse } from 'axios';

import {
  Response,
  FolderItem,
  FolderItemResponse,
  FoldersListResponse,
} from '../app/models';

export const getFoldersListRequest = async (): Promise<Response<FoldersListResponse>> => {
  const response: AxiosResponse<Response<FoldersListResponse>> = await axios.get(`/api/folder/`);
  return response.data;
};

export const removeFolderRequest = async (folderhash: string): Promise<Response<void>> => {
  const response: AxiosResponse<Response<void>> = await axios.delete(`/api/folder/${ folderhash }/`);
  return response.data;
};

export const createNewFolderRequest = async (folderName: string): Promise<Response<FolderItemResponse>> => {
  const response: AxiosResponse<Response<FolderItemResponse>> = await axios.post(`/api/folder/`, {
    name: folderName,
  });

  return response.data;
};

export const updateFolderRequest = async (folderItem: FolderItem): Promise<Response<FolderItemResponse>> => {
  const response: AxiosResponse<Response<FolderItemResponse>> = await axios.put(`/api/folder/${ folderItem.hash }/`, folderItem);

  return response.data;
};

export const clearFolderRequest = async (folderHash: string): Promise<Response<FolderItemResponse>> => {
  const response: AxiosResponse<Response<FolderItemResponse>> = await axios.post(`/api/folder/clear/${ folderHash }/`);

  return response.data;
};
