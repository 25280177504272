import { Action, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { RESET_USER, UPDATE_USER } from '../constants';

import { Response, UserShort } from '../../app/models';
import { socialAuthRequest } from '../../request-agent';

export const updateUser = (user: UserShort): PayloadAction<UserShort> => ({
  type: UPDATE_USER,
  payload: user,
});

export const resetUserAction = (): Action => ({
  type: RESET_USER
});

export const socialLoginAction = (accessToken: string, provider: string) => async (dispatch: Dispatch): Promise<UserShort> => {
  const response: Response<UserShort> = await socialAuthRequest(accessToken, provider);

  if (response?.success) {
    dispatch(updateUser(response.data));
  } else {
    toast.error('Error during google social auth');
  }

  return response?.data;
};
