import axios, { AxiosResponse } from 'axios';

// Models
import { FilesListResponse, FolderItemResponse, Response } from '../app/models';

export const getFolderListRequest = async (folderHash: string, page: number, sort: string, order: string, search?: string): Promise<Response<FilesListResponse>> => {
  console.log(search);
  const requestString: string = search ? `/api/folder/search/${ folderHash }` : `/api/folder/${ folderHash }`;
  const response: AxiosResponse<Response<FilesListResponse>> = await axios.get(requestString, {
    params: {
      page,
      sort,
      order,
      search,
    },
  });

  return response.data;
};

export const moveFilesToFolder = async (filesHashes: Array<string>, folderHash: string): Promise<Response<FolderItemResponse>> => {
  const response: AxiosResponse<Response<FolderItemResponse>> = await axios.post(`/api/folder/put/${folderHash}/`, {
    files: filesHashes,
  });

  return response.data;
};

export const removeMultipleFiles = async (filesHashes: Array<string>, folderHash: string): Promise<Response<FolderItemResponse>> => {
  const response: AxiosResponse<Response<FolderItemResponse>> = await axios.post(`/api/folder/delete/${folderHash}/`, {
    files: filesHashes,
  });

  return response.data;
};
