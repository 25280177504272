import { PayloadAction, Action, Dispatch } from '@reduxjs/toolkit';
import { AsyncAction } from 'redux-promise-middleware';
import { toast } from 'react-toastify';

// Base
import { processingFinished, processingStarted, SortingArgs } from './base';

// Constants
import {
  RESET_LIST,
  UPDATE_LIST,
  TOGGLE_ITEM,
  CLEAR_ALL_ITEMS,
  SELECT_ALL_ITEMS,
  UPLOAD_FILE_STARTED,
  UPLOAD_FILE_FINISHED,
} from '../constants';

// Models
import { FolderItemResponse, FilesListResponse, Response } from '../../app/models';

// Requests
import { clearFolderRequest, getFolderListRequest, moveFilesToFolder, removeMultipleFiles } from '../../request-agent';

interface FolderActionArgs extends SortingArgs {
  folderFromHash: string;
  page: number;
  search?: string;
  updateContent?: boolean;
}

interface RemoveFilesArgs extends FolderActionArgs {
  filesHashes: Array<string>;
}

interface MoveFilesArgs extends RemoveFilesArgs {
  targetFolderHash: string;
}

export const uploadFileStarted = (): Action => ({
  type: UPLOAD_FILE_STARTED,
});

export const uploadFileFinished = (): Action => ({
  type: UPLOAD_FILE_FINISHED,
});

export const toggleListItem = (folderHash: string): PayloadAction<string> => ({
  payload: folderHash,
  type: TOGGLE_ITEM,
});

export const clearAllListItems = (): Action => ({
  type: CLEAR_ALL_ITEMS,
});

export const selectAllListItems = (): Action => ({
  type: SELECT_ALL_ITEMS,
});

export const resetFolderItemsListAction = (): Action => ({
  type: RESET_LIST,
});

export const updateFilesListAction = (folderHash: string, page: number, sort: string, order: string, search?: string): AsyncAction => ({
  type: UPDATE_LIST,
  payload: async (): Promise<FilesListResponse> => {
    const response: Response<FilesListResponse> = await getFolderListRequest(folderHash, page, sort, order, search);

    return response.data;
  },
});

export const removeFilesToFolderAction = (removeFilesArgs: RemoveFilesArgs) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(processingStarted());

  const {
    filesHashes,
    folderFromHash,
    page,
    sort,
    order,
    search,
    updateContent,
  } = removeFilesArgs;
  const response: Response<FolderItemResponse> = await removeMultipleFiles(filesHashes, folderFromHash);

  if (response?.success) {
    toast.success(`File${filesHashes?.length > 1 ? 's': ''} was removed successfully`);
  }

  if (!updateContent) {
    dispatch(updateFilesListAction(folderFromHash, page, sort, order, search));
  }

  dispatch(processingFinished());
};

export const moveFilesToFolderAction = (moveFilesArgs: MoveFilesArgs) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(processingStarted());

  const {
    filesHashes,
    folderFromHash,
    targetFolderHash,
    page,
    sort,
    order,
    search,
    updateContent,
  } = moveFilesArgs;

  const response: Response<FolderItemResponse> = await moveFilesToFolder(filesHashes, targetFolderHash);

  if (response?.success) {
    toast.success(`File${ filesHashes.length === 1 ? '' : 's' } moved successfully`);
  }

  if (!updateContent) {
    dispatch(updateFilesListAction(folderFromHash, page, sort, order, search));
  }

  dispatch(processingFinished());
};

export const clearFolderAction = (folderAction: FolderActionArgs) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(processingStarted());

  const {
    folderFromHash,
    page,
    sort,
    order,
    search,
    updateContent,
  } = folderAction;

  try {
    const respoonse: Response<FolderItemResponse> = await clearFolderRequest(folderFromHash);

    if (respoonse.success) {
      toast.success('All files was deleted');
    }

    if (updateContent) {
      dispatch(updateFilesListAction(folderFromHash, page, sort, order, search));
    }

    dispatch(processingFinished());
  } catch (e: unknown) {
    console.error(e);
    dispatch(processingFinished());
  }
};
