import Joi from "joi";

export const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailJoiStringField: Joi.StringSchema = Joi.string()
  .required()
  .email({ tlds: { allow: false } })
  .messages({
    'string.empty': 'Email field cannot be empty',
    'string.email': 'Email field must be a valid email',
  });

export const validateEmail = (email: string): boolean => {
  return emailReg.test(email);
};

export default validateEmail;
