import React, { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocationState, History, Location } from 'history';
import { toast } from 'react-toastify';

// Components
import { RenameForm } from '../Forms';
import {
  ButtonType,
  ModalContainer,
  RemoveConfirmDialog
} from '..';
import {
  Dropdown,
  DropdownElement,
  DropdownButtonWrapper,
} from '../Dropdown';

// Hooks
import { useCopyToClipboard, useQuery, useStrictNameSchema } from '../../../hooks';

// Models
import { FolderItem } from '../../../models';

// Redux
import {
  getFoldersList,
  clearFolderAction,
  removeFolderAction,
  selectAllListItems,
  updateFolderAction,
} from '../../../../redux';

// Utils
import { getHost } from '../../../../utils';

import routes from '../../../../routes';

interface FolderButtonProps {
  onClick?: () => void;
  className?: string;
  children?: string;
  folder: FolderItem
  isSelected?: boolean;
  hideManageButtons?: boolean;
}

export const FolderButton: FC<FolderButtonProps> = (props: FolderButtonProps): JSX.Element => {
  const {
    folder,
    children,
    className,
    onClick,
    isSelected,
    hideManageButtons,
  } = props;

  const dispatch = useDispatch();
  const history: History<LocationState> = useHistory();
  const location: Location = useLocation();
  const query: URLSearchParams = useQuery();
  const [ pressed, ] = useState<boolean>(false);
  const buttonRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const [ opened, setOpened ] = useState('');
  const { folderHash } = useParams<{ folderHash: string }>();
  const folderList: Array<FolderItem> = useSelector(getFoldersList);
  const [ listSort ] = useLocalStorage('listSort', 'date');
  const [ sortDirection ] = useLocalStorage('sortDirection', 'asc');
  const strictNameSchema = useStrictNameSchema('Folder name');
  const [ , copyToClipboard ] = useCopyToClipboard();

  const renameDone = useCallback((newName: string): void => {
    dispatch(updateFolderAction({
      ...folder,
      name: newName,
    }));
    closeModal('rename');
  }, [ opened, folder ]);

  const removeDone = useCallback((): void => {
    dispatch(removeFolderAction(folder?.hash));
    closeModal('remove');

    if (folder?.hash === folderHash) {
      history.push(`${ routes.list }/${ folderList[folderList.indexOf(folder) - 1]?.hash }`);
    }
  }, [ opened, folder ]);

  const copyLink = useCallback(async (): Promise<void> => {
    await copyToClipboard(`https://${ getHost() }/list/${ folder?.hash }`);
    toast.info('Link added to clipboard');
  }, [ folder?.hash ]);

  const shareFolderToggle = useCallback(async (): Promise<void> => {
    if (!folder?.public) {
      await copyToClipboard(`https://${ getHost() }/list/${ folder?.hash }`);
      toast.info('Link added to clipboard');
    }

    dispatch(updateFolderAction({
      ...folder,
      public: !folder?.public,
    }));
  }, [ folder ]);

  const selectVisibleElements = useCallback(() => dispatch(selectAllListItems()), []);

  const closeModal = (key: string): void => {
    if (opened.includes(key)) {
      setOpened('');
    }
  };

  const getOptionsList = useCallback(() => {
    const list: Array<JSX.Element> = [];

    if (folder?.public) {
      list.push(<DropdownElement key={ 0 }
                                 value="Copy link"
                                 onClick={ copyLink } />);
    }

    if (folder?.name !== 'Unsorted') {
      list.push(<DropdownElement key={ 1 }
                                 value="Rename"
                                 className={ `${ folder.name === 'Unsorted' ? 'hidden' : '' }` }
                                 onClick={ () => setOpened('rename') } />);
    }

    list.push(<DropdownElement key={ 2 }
                               value={ `${ folder?.public ? 'Ushare' : 'Share' }` }
                               onClick={ shareFolderToggle } />);

    if (folder?.hash === folderHash && folder?.files && folder?.files > 0) {
      list.push(<DropdownElement key={ 3 } value="Select visible" onClick={ selectVisibleElements } />);
    }

    if (folder?.files && folder?.files > 0) {
      list.push(<DropdownElement key={ 4 } value="Clear folder" onClick={ () => setOpened('clear') } />);
    }

    if (folder?.name !== 'Unsorted') {
      list.push(<DropdownElement key={ 5 } value="Delete" onClick={ () => setOpened('remove') } />);
    }

    return list;
  }, [ folder ]);

  const clearFolder = useCallback(async (): Promise<void> => {
    const queryPage = query.get('page') || 1;
    const querySearch = query.get('search');
    const searchString = querySearch ? `&search=${ querySearch }` : '';

    await dispatch(clearFolderAction({
      updateContent: folder.hash === folderHash && queryPage === 1,
      folderFromHash: folder.hash,
      order: sortDirection,
      sort: listSort,
      search: query.get('search') || undefined,
      page: 1,
    }));

    closeModal('clear');

    if (queryPage && +queryPage > 1) {
      history.replace({
        pathname: location.pathname,
        search: `page=1${ searchString }`,
      });
    }
  }, [ folder, opened ]);

  return (
    <div
      className={ `folder-button${ className ? ' ' + className : '' }${ isSelected ? ' selected' : '' }${ pressed ? ' pressed' : '' }` }>
      <div className="folder-button-content">
        <button className="folder-button-main" onClick={ onClick }>
          { folder?.name || children || '' }
        </button>
        {
          hideManageButtons
            ? ''
            : (
              <div className="folder-button-side">
                {
                  !folder?.public
                    ? ''
                    : <button className="folder-button-side__share-button" onClick={ copyLink } />
                }

                <div className="folder-button-side__dropdown-wrapper" ref={ buttonRef }>
                  <Dropdown list={ getOptionsList() } stateless>
                    <DropdownButtonWrapper>
                      <button className="folder-button-side__dropdown-button" />
                    </DropdownButtonWrapper>
                  </Dropdown>
                </div>
              </div>
            )
        }
      </div>
      {
        opened === 'rename'
          ? (
            <ModalContainer isOpen={ opened === 'rename' }
                            closeModal={ closeModal }
                            title="Rename folder">
              <RenameForm oldName={ folder?.name || '' }
                          nameSchema={ strictNameSchema }
                          formDone={ renameDone } />
            </ModalContainer>
          ) : ''
      }
      {
        opened === 'remove'
          ? (
            <ModalContainer isOpen={ opened === 'remove' }
                            closeModal={ closeModal }
                            title="Remove folder">
              <RemoveConfirmDialog confirmed={ removeDone }
                                   description={ `Are you sure, that you want remove ${ folder?.name }?` }
                                   confirmType={ ButtonType.Red }
                                   confirmTitle="Remove folder"
                                   denyTitle="Cancel"
                                   denyed={ () => closeModal('remove') } />
            </ModalContainer>
          ) : ''
      }
      {
        opened === 'clear'
          ? (
            <ModalContainer isOpen={ opened === 'clear' }
                            closeModal={ closeModal }
                            title="Clear folder">
              <RemoveConfirmDialog confirmed={ clearFolder }
                                   description="Are you sure you want to permanently remove all files from this folder? This action cannot be undone."
                                   confirmType={ ButtonType.Red }
                                   confirmTitle="Clear folder"
                                   denyTitle="Cancel"
                                   denyed={ () => closeModal('clear') } />
            </ModalContainer>
          ) : ''
      }
    </div>
  );
};

export default FolderButton;
