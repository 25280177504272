import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { LazyComponentProps, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

import { ListItem } from '../../../models';

interface ContentElementProps extends LazyComponentProps {
  description?: string;
  link?: string;
  toggled?: boolean;
  element: ListItem;
  toggle?: (fileHash: string) => void;
}

export const ContentElement: React.ComponentClass<Omit<ContentElementProps, 'scrollPosition'>> |
  React.FunctionComponent<Omit<ContentElementProps, 'scrollPosition'>> = trackWindowScroll((props: ContentElementProps): JSX.Element => {
  const {
    scrollPosition,
    element,
    link,
    toggled,
    toggle,
  } = props;

  const getHost = (): string => {
    return process.env.NODE_ENV === `development` ? 'smaker.faifly.com' : window.location.host;
  };

  const toggleClick = (): void => {
    if (toggle !== undefined && element?.hash !== null && element?.hash !== undefined) {
      toggle(element?.hash);
    }
  };

  return (
    <button className={ `content-element${ toggled ? ' ' + 'selected' : '' }` }
            onClick={ toggleClick }
            title={ `${ element?.name }.${ element?.ext }` }
    >
      <div className="content-element-image">
        <LazyLoadImage className="content-element-image__element"
                       placeholder={ <h1 className="content-element-image__element-placeholder">LOADING</h1> }
                       scrollPosition={ scrollPosition }
                       src={ `https://${ getHost() }${ element.thumb }` }
                       alt={ `${ element?.name }.${ element?.ext }` } />
      </div>
      <p className="content-element__description">
        { `${ element?.name }.${ element?.ext }` }
      </p>
      <div className="checkbox-container" />
      <div className="link-container">
        <Link onClick={ (event: MouseEvent<HTMLAnchorElement>) => event.stopPropagation() }
              className="link-container__link"
              to={ link || '#' }>
          open
        </Link>
      </div>
    </button>
  );
});

export default ContentElement;
