import React, { FC } from 'react';
import { Action, OutputSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Components
import { Button, ButtonType } from '../Button';

// Hooks
import { useCopyToClipboard } from '../../../hooks';

// Models
import { ListItem } from '../../../models';

// Redux
import { GlobalReduxState } from '../../../../redux';

// Utils
import { formatBytes, getHost } from '../../../../utils';

interface ItemsInfoHeaderProps {
  allItemsSelector: OutputSelector<GlobalReduxState, Array<ListItem>, (res: GlobalReduxState) => Array<ListItem>>;
  selectedItemsSelector: OutputSelector<GlobalReduxState, Array<string>, (res: GlobalReduxState) => Array<string>>;
  clearAllAction: () => Action;
  selectAllAction: () => Action;
  openModal?: (modalName: string) => void;
  moveButton?: boolean;
  removeButton?: boolean;
  className?: string;
}

export const ItemsInfoHeader: FC<ItemsInfoHeaderProps> = (props: ItemsInfoHeaderProps): JSX.Element => {
  const {
    allItemsSelector,
    selectedItemsSelector,
    clearAllAction,
    selectAllAction,
    openModal,
    moveButton,
    removeButton,
    className,
  } = props;
  const dispatch = useDispatch();
  const allItemsList: Array<ListItem> = useSelector(allItemsSelector);
  const selectedItemsList: Array<string> = useSelector(selectedItemsSelector);
  const [ , copyToClipboard ] = useCopyToClipboard();

  const clearAllItemsList = (): void => {
    dispatch(clearAllAction());
  };

  const selectAllVisible = (): void => {
    dispatch(selectAllAction());
  };

  const getSelectedFilesSize = (): string => {
    let sizeBytes = 0;

    allItemsList.forEach((file: ListItem): void => {
      if (selectedItemsList.includes(file.hash)) {
        sizeBytes += file.size;
      }
    });

    return formatBytes(sizeBytes);
  };

  const modalOpen = (modalName: string): void => {
    if (openModal) {
      openModal(modalName);
    }
  };

  const getSelectedFile = (): ListItem => {
    return allItemsList.find((item: ListItem): boolean => item.hash === selectedItemsList[0]) as ListItem;
  };

  const getFileInfo = (mobile?: boolean): string => {
    let title = '';

    if (selectedItemsList.length > 0) {
      if (selectedItemsList.length > 1) {
        if (mobile) {
          title = `${ selectedItemsList.length } files`;
        } else {
          title = `Selected: ${ selectedItemsList.length } files (${ getSelectedFilesSize() })`;
        }
      } else {
        if (mobile) {
          title = `${ selectedItemsList.length } file`;
        } else {
          title = `${ getSelectedFile()?.name }.${ getSelectedFile()?.ext } (${ getSelectedFilesSize() })`;
        }
      }
    }

    return title;
  };

  return (
    <div className={ `page-header-info${ className ? ' ' +className : '' }` }>
      <div>
        <h4 className="page-header-info__file-info">
          { getFileInfo() }
        </h4>
        <h4 className="page-header-info__file-info mobile">
          { getFileInfo(true) }
        </h4>
      </div>
      <div className="page-header-file-actions">
        <Button buttonType={ ButtonType.Transparent }
                className="page-header-file-actions__select-visible"
                onClick={ selectAllVisible }
                value="Select visible" />
        {
          selectedItemsList.length <= 1
            ? <Button buttonType={ ButtonType.Transparent }
                      className="page-header-file-actions__share"
                      onClick={ async (): Promise<void> => {
                        await copyToClipboard(`https://${ getHost() }/file/${ selectedItemsList[0] }`);
                        toast.info('Link added to clipboard');
                      } } />
            : <div />
        }
        {
          moveButton === undefined || moveButton
            ? <Button buttonType={ ButtonType.Transparent }
                      className="page-header-file-actions__move"
                      onClick={ () => modalOpen('move') } />
            : ''
        }
        {
          removeButton === undefined || removeButton
            ? <Button buttonType={ ButtonType.Transparent }
                      className="page-header-file-actions__remove"
                      onClick={ () => modalOpen('remove') } />
            : ''
        }
      </div>
      <div className="page-header-file-side">
        <Button buttonType={ ButtonType.Regular }
                className="page-header-file-side__cancel"
                onClick={ clearAllItemsList }
                value="Cancel" />
      </div>
    </div>
  );
};

export default ItemsInfoHeader;
