import React, { FC, MutableRefObject, ReactElement, useRef, useState } from 'react';
import Modal, { Styles } from 'react-modal';

// Component props
import { DropdownElementProps } from './Components';

interface DropdownProps {
  list: Array<ReactElement>;
  children?: ReactElement;
  stateless?: boolean;
  defaultSelected?: string;
}

export const Dropdown: FC<DropdownProps> = (props: DropdownProps): JSX.Element => {
  const [ showList, setShowList ] = useState<boolean>(false);
  const {
    children,
    list,
    stateless,
    defaultSelected,
  } = props;

  const [ selectedItem, setSelectedItem ] = useState<number>(list.indexOf(list.find(file => file.key === defaultSelected) as React.ReactElement<DropdownElementProps, string | React.JSXElementConstructor<DropdownElementProps>>));
  const [ dropdownStyle, setDropdownStyle ] = useState({});
  const dropdownRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);

  const toggleList = (state?: boolean): void => {
    if (state !== undefined) {
      setShowList(state);
    } else {
      setShowList(!showList);
    }
  };

  const modalStyles: Styles = {
    content: {
      position: 'unset',
      padding: 0,
      background: 'transparent',
      border: undefined,
      inset: 0,
    },
    overlay: {
      backgroundColor: 'none',
    },
  };

  const getDropdownStyle = (rect: DOMRect): void => {
    setDropdownStyle({
      display: 'block',
      visibility: 'hidden',
    });

    setTimeout((): void => {
      const dropdownRect = dropdownRef?.current?.getBoundingClientRect();

      if (rect !== undefined) {
        let top: number = rect?.bottom + 10;

        if (dropdownRect && top + dropdownRect?.height > window.innerHeight) {
          top = window.innerHeight - (dropdownRect?.height + 10);
        }

        setDropdownStyle({
          display: 'block',
          top,
          right: window.innerWidth - rect?.right,
        });
      }
    }, 10);
  };

  return (
    <div className="dropdown">
      <div className="dropdown__element">
        {
          children
            ? React.cloneElement(children, {
              ...children?.props,
              toggleList,
              setPos: getDropdownStyle,
              toggled: showList,
              value: list[selectedItem]?.props?.value || children.props?.value,
            })
            : null
        }
      </div>
      {
        showList
          ? <Modal isOpen={ true }
                   style={ modalStyles }
                   onRequestClose={ () => setShowList(false) }>
            <div className="dropdown-list"
                 style={ dropdownStyle }
                 ref={ dropdownRef }
            >
              <ul>
                {
                  list.map((item: ReactElement) => {
                    return React.cloneElement(item, {
                      ...item.props,
                      onClick: (): void => {
                        const itemIndex: number = list.indexOf(item);

                        if (selectedItem !== itemIndex || stateless) {
                          setSelectedItem(itemIndex);
                        }

                        console.log(item?.props?.onClick);

                        if (item?.props?.onClick) {
                          item.props.onClick();
                        }

                        setShowList(false);
                      },
                    });
                  })
                }
              </ul>
            </div>
          </Modal>
          : ''
      }
    </div>
  );
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
