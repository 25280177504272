import React, { FC } from 'react';

interface SettingsEntryProps {
  title?: string;
  value?: string;
  ready?: boolean;
  onClick?: () => void;
}

export const SettingsEntry: FC<SettingsEntryProps> = (props: SettingsEntryProps): JSX.Element => {
  const { value, title, ready, onClick } = props;

  return (
    <div className={ `page-sidebar-setitngs-item${ ready === undefined || ready ? '' : ' disabled' }` }>
      <h4 className="page-sidebar-setitngs-item__title">
        { value }
      </h4>
      <button className="page-sidebar-setitngs-item__button"
              onClick={ onClick }
      >
        { title }
      </button>
    </div>
  );
};

export default SettingsEntry;
