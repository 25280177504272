import { createSelector } from '@reduxjs/toolkit';

import { ListState } from '../../app/models';

export interface SelectorListState {
  list: ListState,
}

const selectSelf = (state: SelectorListState) => state;

export const getListInfo = createSelector(
  selectSelf, (state: SelectorListState) => state.list
);

export const getSelectedItemsList = createSelector(
  selectSelf, (state: SelectorListState) => state.list.selected
);

export const getAllItemsList = createSelector(
  selectSelf, (state: SelectorListState) => state.list.items
);

export const getListPagination = createSelector(
  selectSelf, (state: SelectorListState) => state.list.pagination
);

export const getUploadingState = createSelector(
  selectSelf, (state: SelectorListState) => state.list.uploading
);
