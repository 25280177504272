import React from 'react';

import logo from '../../../../assets/images/logo.svg';

export const Logo = (): JSX.Element => {
  return (
    <img src={ logo } alt="logo" className="logo" />
  );
};

export default Logo;
