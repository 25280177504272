import React, { FC } from 'react';

import { Button, ButtonType } from '../Button';

interface BreadcrumbsProps {
  pathTitle?: string;
  buttonTitle?: string;
  onClick: () => void;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = (props: BreadcrumbsProps): JSX.Element => {
  const { pathTitle, buttonTitle, onClick } = props;

  return (
    <div className="page-header-breadcrumbs">
      <h2 className="page-header-breadcrumbs__title">
        { pathTitle }
      </h2>
      <Button className="page-header-breadcrumbs__button"
              onClick={ onClick }
              value={ buttonTitle }
              buttonType={ ButtonType.Transparent } />
    </div>
  );
};

export default Breadcrumbs;
