import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OutputSelector, PayloadAction } from '@reduxjs/toolkit';

// Components
import { ContentElement } from '../ContentElement';

// Models
import { ListItem, ListState } from '../../../models';

// Redux
import { GlobalReduxState } from '../../../../redux';

// Routes
import { routes } from '../../../../routes';

import listItemPlaceholder from '../../../../assets/images/placeholders/file-list-element.svg';


interface ContentGridProps {
  allItemsSelector: OutputSelector<GlobalReduxState, ListState, (res: GlobalReduxState) => ListState>;
  selectedItemSelector: OutputSelector<GlobalReduxState, Array<string>, (res: GlobalReduxState) => Array<string>>;
  toggledAction: (fileHash: string) => PayloadAction<string>;
  emptyContent: JSX.Element;
}

export const ContentGrid: FC<ContentGridProps> = (props: ContentGridProps): JSX.Element => {
  const { allItemsSelector, selectedItemSelector, toggledAction, emptyContent } = props;
  const dispatch = useDispatch();
  const { items: allItemsList, loaded } = useSelector(allItemsSelector);
  const selectedItemsList: Array<string> = useSelector(selectedItemSelector);

  const toggleItem = (fileHash: string): void => {
    dispatch(toggledAction(fileHash));
  };

  const getNewList = (): Array<JSX.Element> => {
    if (loaded) {
      return allItemsList?.map(
        (item: ListItem) =>
          <ContentElement toggled={ selectedItemsList.includes(item.hash) }
                          toggle={ toggleItem }
                          element={ item }
                          link={ `${ routes.file }/${ item.hash }` }
                          key={ item.hash } />
      );
    } else {
      const arr = new Array(10).fill(Math.random(), 0, 10);
      return arr
        .map((item, index) =>
          <img key={ index }
               className="content-element-placeholder"
               src={ listItemPlaceholder }
               alt="folder-item-placeholder" />);
    }
  };

  let itemsList: Array<JSX.Element> = getNewList();

  useEffect((): void => {
    itemsList = getNewList();
  }, [ allItemsList, selectedItemsList ]);

  return (
    <>
      <div className="content-grid" style={ { display: !loaded || itemsList.length ? 'grid' : 'none' } }>
        { itemsList }
      </div>
      <div className="empty-folder-content" style={ { display: loaded && !itemsList.length ? 'flex' : 'none' } }>
        { emptyContent }
      </div>
    </>
  );
};

export default ContentGrid;
