import { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { AsyncAction } from 'redux-promise-middleware';
import { toast } from 'react-toastify';

// Actions
import { clearAllListItems } from './list';

// Constants
import {
  CREATE_FOLDER,
  REMOVE_FOLDER,
  SELECT_FOLDER,
  UPDATE_FOLDER,
  UPDATE_FOLDERS_LIST,
} from '../constants';

// Models
import {
  Response,
  FolderItem,
  FolderItemResponse,
  FoldersListResponse,
} from '../../app/models';

// Requests
import {
  updateFolderRequest,
  removeFolderRequest,
  getFoldersListRequest,
  createNewFolderRequest,
} from '../../request-agent';

export const updateFolderListAction = (newList: Array<FolderItem>): PayloadAction<Array<FolderItem>> => ({
  payload: newList,
  type: `${ UPDATE_FOLDERS_LIST }_FULFILLED`,
});

export const resetFolderListAction = (): PayloadAction<Array<FolderItem>> => ({
  type: `${ UPDATE_FOLDERS_LIST }_FULFILLED`,
  payload: [],
});

export const createNewFolderAction = (folderName: string): AsyncAction => ({
  type: CREATE_FOLDER,
  payload: async (): Promise<FolderItem> => {
    const response: Response<FolderItemResponse> = await createNewFolderRequest(folderName);

    if (response?.success) {
      toast.success(`Folder ${ folderName } was successfully created`);
    }

    return response.data.folder;
  },
});

export const removeFolderAction = (folderHash: string): AsyncAction => ({
  type: REMOVE_FOLDER,
  payload: async (): Promise<string> => {
    const response: Response<void> = await removeFolderRequest(folderHash);

    if (response?.success) {
      toast.success(`Folder successfully removed`);
    }

    return folderHash;
  },
});

export const fetchFoldersListAction = (): AsyncAction => ({
  type: UPDATE_FOLDERS_LIST,
  payload: async (): Promise<Array<FolderItem>> => {
    const response: Response<FoldersListResponse> = await getFoldersListRequest();
    return response.data.folders;
  },
});

export const updateFolderAction = (folderItem: FolderItem): AsyncAction => ({
  type: UPDATE_FOLDER,
  payload: async (): Promise<FolderItem> => {
    const respoonse: Response<FolderItemResponse> = await updateFolderRequest(folderItem);

    if (respoonse.success) {
      toast.success(`Folder "${ respoonse?.data?.folder?.name }" successfully updated`);
    }

    return respoonse.data.folder;
  },
});

export const selectFolderAction = (hash: string): PayloadAction<string> => ({
  payload: hash,
  type: SELECT_FOLDER,
});

export const selectFolderPlease = (hash: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(selectFolderAction(hash));
    dispatch(clearAllListItems());
  };
};
