import { createSelector } from '@reduxjs/toolkit';

import { FileState, FolderItem, Pagination, UserShort } from '../../app/models';

export interface SelectorFileState {
  folder: FolderItem;
  file: FileState;
  user: UserShort;
  pagination: Pagination;
}

const selectSelf = (state: SelectorFileState) => state;

export const getFileInfo = createSelector(
  selectSelf, (state: SelectorFileState) => state.file
);
