import React, { ChangeEvent, FC, useState } from 'react';

import './TextInput.scss';

interface TextInputParams {
  inputName: string;
  type: string;
  value: string;
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  error?: string;
  showError?: boolean;
  simpleMode?: boolean;

  valueChange(name: string, value: string): void;
}

export const TextInput: FC<TextInputParams> = (params: TextInputParams): JSX.Element => {
  const {
    value,
    inputName,
    valueChange,
    type,
    placeholder,
    className,
    showError,
    error,
    wrapperClassName,
    simpleMode,
  } = params;
  const [ state, setState ] = useState({
    toggled: params.type !== 'password',
  });

  const eyeToggle = (): void => {
    setState({
      ...state,
      toggled: !state.toggled,
    });
  };

  const inputChange = (input: ChangeEvent<HTMLInputElement>): void => {
    valueChange(inputName, input.target.value);
  };

  return (
    <div className={ `text-input${ wrapperClassName ? ' ' + wrapperClassName : '' }` }>
      <input name={ inputName }
             placeholder={ placeholder }
             className={ className?.length ? 'text-input__element ' + className : 'text-input__element' }
             type={ type === 'password' ? (state.toggled ? 'string' : 'password') : type }
             autoComplete="off"
             value={ value }
             onChange={ inputChange } />
      {
        params.type === 'password' && !state.toggled
          ? <input type="button" className="text-input__eye" onClick={ eyeToggle } />
          : ''
      }
      {
        params.type === 'password' && state.toggled
          ? <input type="button" className="text-input__eye text-input__eye_off" onClick={ eyeToggle } />
          : ''
      }
      {
        !simpleMode
          ? (
            <p className={ `text-input__error${ showError ? ' text-input__error_visible' : '' }` }>
              { showError && error ? error : 'no-error' }
            </p>
          ) : ''
      }
    </div>
  );
};

export default TextInput;
