import { AnyAction, Draft } from '@reduxjs/toolkit';

import { FolderState, FolderItem } from '../../app/models';

import {
  CREATE_FOLDER,
  RENAME_FOLDER,
  REMOVE_FOLDER,
  UPDATE_FOLDER,
  UPDATE_FOLDERS_LIST,
} from '../constants';

const folderObject: FolderState = {
  list: [],
  loaded: false,
};

export const folders = (state: Draft<FolderState> = folderObject, action: AnyAction): FolderState => {
  switch (action.type) {
    case `${ UPDATE_FOLDERS_LIST }_FULFILLED`:
      return {
        ...(state as FolderState),
        list: action.payload,
        loaded: true,
      };
    case `${ RENAME_FOLDER }_FULFILLED`:
      return {
        ...(state as FolderState),
        list: [
          ...(state as FolderState).list.map((folder: FolderItem): FolderItem => {
            if (folder.hash === action?.hash) {
              folder.name = action?.name || 'new name';
            }

            return folder;
          }),
        ],
      };
    case `${ CREATE_FOLDER }_FULFILLED`:
      return {
        ...(state as FolderState),
        list: [
          ...(state as FolderState).list,
          action.payload,
        ],
      };
    case `${ REMOVE_FOLDER }_FULFILLED`:
      return {
        ...(state as FolderState),
        list: [
          ...(state as FolderState).list.filter((folder: FolderItem) => {
            if (folder.hash !== action.payload) {
              return folder;
            }
          }),
        ],
      };
    case `${ UPDATE_FOLDER }_FULFILLED`:
      return {
        ...(state as FolderState),
        list: [
          ...(state as FolderState).list.map((folder: FolderItem) => {
            return folder.hash === action.payload.hash ? action.payload : folder;
          }),
        ],
      };
    default:
      return state;
  }
};
