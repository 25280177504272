import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Components
import { UploadProgress } from '../../../pages/List/Components/ListHeader/Components/UploadProgress';
import { Button, ButtonType, ModalContainer } from '../index';

// Hooks
import { useQuery } from '../../../hooks';

// Models
import { FileRequest, FileResponse, Response } from '../../../models';

// Redux
import {
  getUploadingState,
  uploadFileStarted,
  uploadFileFinished,
  updateFilesListAction
} from '../../../../redux';

// Utils
import { getHost } from '../../../../utils';

const modalContainerStyle = {
  content: {
    top: 'calc(100vh - 150px)',
    left: 'calc(50vw - calc(440px / 2))',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    backgroundColor: 'transparent',
  },
};

const restrictedSymbols = ['$', '&', '+', ',', ':', ';', '=', '?', '@', '#', '"', '`', '~', '№', '|', '\'', '<', '>', '^', '*', '(', ')', '%', '!'];
const getExt = (filename: string) => filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
const getFileNameWithoutExt = (filename: string) => filename.substring(0, filename.lastIndexOf('.')) || filename;
const trimSpecSymbols = (input: string) => {
  let result: string = input;

  restrictedSymbols.forEach((symbol: string): void => {
    result = result.replaceAll(symbol, '');
  });

  return result;
};

const generateThumbnail = (file: File, boundBox: Array<number>): Promise<string> => {
  if (!boundBox || boundBox.length !== 2) {
    throw 'You need to give the boundBox';
  }

  const reader: FileReader = new FileReader();
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

  return new Promise((resolve): void => {
    reader.onload = function (event: ProgressEvent<FileReader>): void {
      const img: HTMLImageElement = new Image();

      img.onload = () => {
        const scaleRatio: number = Math.min(...boundBox) / Math.max(img.width, img.height);
        const width: number = img.width * scaleRatio;
        const height: number = img.height * scaleRatio;
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        return resolve(canvas.toDataURL(file.type));
      };

      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};

interface UploadButtonProps {
  className?: string;
  children?: JSX.Element;
}

export const UploadButton: FC<UploadButtonProps> = (props: UploadButtonProps): JSX.Element => {
  const { className, children } = props;
  const dispatch = useDispatch();
  const query: URLSearchParams = useQuery();
  const { folderHash } = useParams<{ folderHash: string }>();
  const uploadInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null);
  const uploadingState = useSelector(getUploadingState);
  const [ modalOpen, setModalOpen ] = useState<boolean>(false);
  const [ progress, setProgress ] = useState<number>(0);
  const [ link, setLink ] = useState<string>('');
  const [ fileName, setFileName ] = useState<string>();
  const [ listSort ] = useLocalStorage('listSort', 'date');
  const [ sortDirection ] = useLocalStorage('sortDirection', 'asc');
  let timeout: number;

  const closeModal = (): void => {
    if (progress !== 100) {
      return;
    }

    setModalOpen(false);
    setProgress(0);
  };

  const uploadFileAction = async (file: FileRequest): Promise<void> => {
    setModalOpen(true);
    dispatch(uploadFileStarted());

    const response: AxiosResponse<Response<FileResponse>> = await axios.post(`/api/file/`, file, {
      onUploadProgress: (progressEvent): void => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    });

    try {
      if (response.data?.success) {
        updateFolderContent();
        setLink(`https://${ getHost() }/file/${ response.data?.data?.file?.hash }`);
        toast.success(`File ${ file.name }.${ file?.fileExtension } was uploaded successfully`);
      } else {
        dispatch(uploadFileFinished());
        toast.error(`File ${ file.name } uploading error`);
      }
    } catch {
      dispatch(uploadFileFinished());

      if (response?.data?.errors &&
        response.data.errors['general'] &&
        response.data.errors['general']?.length > 0) {
        if (response.data.errors['general'][0].includes('upload size limit')) {
          toast.error('The file cannot be uploaded - you have reached the upload size limit');
        }
      } else {
        toast.error(`File ${ file.name } uploading error`);
      }
    }

    dispatch(uploadFileFinished());
  };

  const openFile = (): void => {
    if (!uploadInputRef.current) return;

    uploadInputRef.current.click();
  };

  const updateFolderContent = (): void => {
    const queryPage = query.get('page') || 1;
    const querySearch = query.get('search') || undefined;

    dispatch(updateFilesListAction(folderHash, +queryPage, listSort, sortDirection, querySearch));
  };

  const fileChanged = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target?.files?.length) return;
    if (!event.target?.files[0]?.type?.startsWith('image/jpeg') &&
      !event.target?.files[0]?.type?.startsWith('image/png') &&
      !event.target?.files[0]?.type?.startsWith('image/jpg')) {
      toast.error('File extension isn’t supported, please use PNG, JPG, JPEG');

      if (event.target) {
        event.target.files = new DataTransfer()?.files;
      }

      return;
    }

    event.persist();

    const reader: FileReader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);

    let fileBase64: string;

    reader.onloadend = async (): Promise<void> => {
      if (file?.size >= 20971520) {
        toast.error('Maximum file size is 16mb');
        if (event.target) {
          event.target.files = new DataTransfer()?.files;
        }
        return;
      }

      setFileName(file?.name);
      fileBase64 = reader.result?.toString() || '';

      if (!fileBase64) {
        toast.error('File reading error');
        return;
      }

      const thumbBase64: string = await generateThumbnail(file, [ 300, 300 ]);

      try {
        let fileName: string = file.name;

        if (fileName.length >= 100) {
          fileName = fileName.slice(0, 95);
        }

        await uploadFileAction({
          folder: folderHash,
          fileExtension: getExt(file?.name),
          name: trimSpecSymbols(getFileNameWithoutExt(fileName)),
          data: fileBase64,
          thumb: thumbBase64,
        });
      } catch (error: unknown) {
        closeModal();
      }

      if (event.target) {
        event.target.files = new DataTransfer()?.files;
      }
    };
  };

  const getButton = (): JSX.Element => {
    if (children) {
      return React.cloneElement(children, {
        ...children.props,
        onClick: openFile,
      });
    } else {
      return <Button className={ className }
                     buttonType={ ButtonType.Regular }
                     value="Upload image"
                     disabled={ uploadingState }
                     onClick={ openFile } />;
    }
  };

  useEffect(() => {
    if (!uploadingState) {
      timeout = setTimeout((): void => {
        if (!uploadingState) {
          setModalOpen(false);
          setProgress(0);
        }
      }, 5000) as unknown as number;
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [ uploadingState ]);

  return (
    <div className="upload-button-container">
      { getButton() }
      <input type="file"
             name="file"
             accept=".jpg, .jpeg, .png"
             ref={ uploadInputRef }
             onChange={ fileChanged }
             className="upload-button-container__input_control" />
      <ModalContainer isOpen={ modalOpen }
                      overlayClassName="progress-bar-overlay"
                      closeModal={ closeModal }
                      customStyles={ modalContainerStyle }
                      title={ `Upload file: ${ fileName }` }
      >
        <UploadProgress progress={ progress } link={ link } />
      </ModalContainer>
    </div>
  );
};
