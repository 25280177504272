import { AsyncAction } from 'redux-promise-middleware';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { FILE_INFO_UPDATE, REMOVE_FILE, UPATE_FILE, UPDATE_SHORT_URL } from '../constants';
import { Response, FileResponse, FileUpdateRequest, ShortUrl } from '../../app/models';
import {
  shortLinkRequest,
  updateFileRequest,
  removeFileRequest,
  getFileInfoRequest,
} from '../../request-agent';
import { processingFinished, processingStarted, SortingArgs } from './base';

export const removedFileAction = (): Action => ({
  type: REMOVE_FILE,
});

export const getFileInfoAction = (fileHash: string, sortings: SortingArgs): AsyncAction => ({
  type: FILE_INFO_UPDATE,
  payload: async (): Promise<FileResponse> => {
    const { sort, order } = sortings;
    const response: Response<FileResponse> = await getFileInfoRequest(fileHash, sort, order);

    return response.data;
  },
});

export const updateFileAction = (file: FileUpdateRequest): AsyncAction => ({
  type: UPATE_FILE,
  payload: async (): Promise<FileResponse> => {
    const response: Response<FileResponse> = await updateFileRequest(file);

    if (response?.success) {
      toast.success('File updated successfully');
    }

    return response.data;
  },
});

export const getShortUrlAction = (fileHash: string): AsyncAction => ({
  type: UPDATE_SHORT_URL,
  payload: async (): Promise<ShortUrl> => {
    const response: Response<ShortUrl> = await shortLinkRequest(fileHash);

    return response.data;
  },
});

export const removeFileAction = (fileHash: string) => async (dispatch: Dispatch): Promise<boolean> => {
  dispatch(processingStarted());

  try {
    const response: Response<void> = await removeFileRequest(fileHash);

    if (response?.success) {
      toast.success('File removed successfully');
      dispatch(removedFileAction());
    }

    dispatch(processingFinished());
    return response?.success;
  }
  catch (e: unknown) {
    dispatch(processingFinished());
    return false;
  }
};
