import { createSelector } from '@reduxjs/toolkit';

import { FolderState } from '../../app/models';

export interface SelectorFolderState {
  folders: FolderState,
}

const selectSelf = (state: SelectorFolderState) => state;

export const getFoldersInfo = createSelector(
  selectSelf, (state: SelectorFolderState) => state.folders
);

export const getFoldersList = createSelector(
  selectSelf, (state: SelectorFolderState) => state.folders.list
);
