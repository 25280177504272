import React, { FC, useState } from 'react';

import { UseFormRegisterReturn } from 'react-hook-form';

import '../TextInput.scss';

interface FormTextInputParams {
  type: string;
  placeholder?: string;
  className?: string;
  error?: string;
  showError?: boolean;
  register: UseFormRegisterReturn;
  autoFocus?: boolean;
}

export const FormTextInput: FC<FormTextInputParams> = (params: FormTextInputParams): JSX.Element => {
  const {
    type,
    placeholder,
    className,
    showError,
    error,
    register,
    autoFocus,
  } = params;
  const [ state, setState ] = useState({
    toggled: params.type !== 'password',
  });

  const eyeToggle = (): void => {
    setState({
      ...state,
      toggled: !state.toggled,
    });
  };

  return (
    <div className="text-input">
      <input { ...register }
             placeholder={ placeholder }
             autoFocus={ autoFocus !== undefined ? autoFocus : false }
             className={ className?.length ? 'text-input__element ' + className : 'text-input__element' }
             type={ type === 'password' ? (state.toggled ? 'string' : 'password') : type }
             autoComplete="off" />
      {
        params.type === 'password' && !state.toggled ?
          <input type="button" className="text-input__eye" onClick={ eyeToggle } /> : ''
      }
      {
        params.type === 'password' && state.toggled ?
          <input type="button" className="text-input__eye text-input__eye_off"
                 tabIndex={ -1 }
                 onClick={ eyeToggle } /> : ''
      }
      <p className={ `text-input__error${ showError ? ' text-input__error_visible' : '' }` }>
        { showError && error ? error : 'no-error' }
      </p>
    </div>
  );
};

export default FormTextInput;
