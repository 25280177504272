// eslint need to be disabled, since we should be able to convert any objects

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const camelToSnake = (string: string): string => string.replace(/[A-Z]/g, (letter: string): string => `_${ letter.toLowerCase() }`);
export const snakeToCamel = (string: string): string => string.replace(/_[a-z]/g, (letter: string): string => letter.split('_')[1].toUpperCase());

export enum cases {
  camel = 1,
  snake = 2,
}

export const keysToCase = (inputObject: any, targetCase: number): any => {
  if (Array.isArray(inputObject)) {
    return inputObject.map((i) => keysToCase(i, targetCase));
  }

  const resultObject = {};
  Object.keys(inputObject)
    .forEach((key: string): void => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (inputObject[key] instanceof Object && !Array.isArray(inputObject[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultObject[targetCase === cases.camel ? snakeToCamel(key) : camelToSnake(key)] = keysToCase(inputObject[key], targetCase);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resultObject[targetCase === cases.camel ? snakeToCamel(key) : camelToSnake(key)] = inputObject[key];
      }
    });
  return resultObject;
};

/* eslint-enable no-use-before-define */
/* eslint-enable @typescript-eslint/no-explicit-any */
export default camelToSnake;
