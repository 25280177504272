import React, { FC } from 'react';
import { useLocalStorage, writeStorage  } from "@rehooks/local-storage";

export const Burger: FC = (): JSX.Element => {
  const [ sidebar ] = useLocalStorage('sidebar', 'show');

  const toggleSidebar = (): void => {
    switch (sidebar) {
      case 'show': writeStorage('sidebar', 'hide'); break;
      case 'hide': writeStorage('sidebar', 'show'); break;
      default: writeStorage('sidebar', 'hide');
    }
  };

  return (
    <button className="burger" onClick={ toggleSidebar }>
      <span className="burger-line" />
      <span className="burger-line" />
      <span className="burger-line" />
    </button>
  );
};

export default Burger;
