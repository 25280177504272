import axios, { AxiosResponse } from 'axios';

import { Response, FileResponse, FileUpdateRequest, ShortUrl } from '../app/models';

export const getFileInfoRequest = async (fileHash: string, sort: string, order: string): Promise<Response<FileResponse>> => {
  const response: AxiosResponse<Response<FileResponse>> = await axios.get(`/api/file/${ fileHash }`, {
    params: {
      sort,
      order,
    },
  });

  return response?.data;
};

export const updateFileRequest = async (file: FileUpdateRequest): Promise<Response<FileResponse>> => {
  const response: AxiosResponse<Response<FileResponse>> = await axios.put(`/api/file/${ file.hash }`, file);

  return response?.data;
};

export const removeFileRequest = async (fileHash: string): Promise<Response<void>> => {
  const response: AxiosResponse<Response<void>> = await axios.delete(`/api/file/${ fileHash }`);

  return response?.data;
};

export const shortLinkRequest = async (fileHash: string): Promise<Response<ShortUrl>> => {
  const response: AxiosResponse<Response<ShortUrl>> = await axios.get(`/api/file/short/${ fileHash }`);

  return response?.data;
};
