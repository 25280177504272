import { Action } from '@reduxjs/toolkit';
import { PROCESS } from '../constants';

export interface SortingArgs {
  sort: string;
  order: string;
}

export const processingStarted = (): Action => ({
  type: `${ PROCESS }_PENDING`,
});

export const processingFinished = (): Action => ({
  type: `${ PROCESS }_FULFILLED`,
});
