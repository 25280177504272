import React, { FC } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { toast } from 'react-toastify';

import { Button, ButtonType } from '../../../../../../shared';

// Hooks
import { useCopyToClipboard } from '../../../../../../hooks';

interface UploadProgressProps {
  progress: number;
  link?: string;
}

export const UploadProgress: FC<UploadProgressProps> = (props: UploadProgressProps): JSX.Element => {
  const { progress, link } = props;
  const [ , copyToClipboard ] = useCopyToClipboard();

  return (
    <div className="upload-progress">
      <ProgressBar completed={ progress }
                   transitionDuration="0.2s"
                   className="progress-bar"
                   labelAlignment="center" />
      <Button className="upload-progress__link"
              disabled={ progress !== 100 }
              buttonType={ ButtonType.Transparent }
              onClick={ async (): Promise<void> => {
                if (link) {
                  await copyToClipboard(link);
                  toast.info('Link was added to clipboard');
                }
              } } />
    </div>
  );
};

export default UploadProgress;
