import { useState } from 'react';
import { copyToClipboard } from '../../utils';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

export const useCopyToClipboard = (): [ CopiedValue, CopyFn ] => {
  const [ copiedText, setCopiedText ] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text: string): Promise<boolean> => {
    if (navigator?.clipboard) {
      // Try to save to clipboard then save it in the state if worked
      try {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        return true;
      } catch (error: unknown) {
        console.warn('Copy failed', error);
        setCopiedText(null);
        return false;
      }
    } else {
      console.warn('Clipboard not supported. Using fallback.');
      copyToClipboard(text);
      setCopiedText(text);
      return true;
    }
  };

  return [ copiedText, copy ];
};

export default useCopyToClipboard;
