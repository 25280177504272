import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { History, LocationState } from 'history';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '@rehooks/local-storage';
import Joi from 'joi';

// Components
import { RenameForm } from '..';
import { SettingsEntry } from './Components';
import { Button, ButtonType } from '../Button';
import { ModalContainer } from '../ModalContainer';

// Models
import { UserShort, DateFormat } from '../../../models';

// Redux
import {
  getUserInfo,
  resetUserAction,
  resetFolderListAction,
  changeEmailRequestThunk,
  updateUserNicknameAction,
  resetPasswordRequestThunk,
  resetFolderItemsListAction,
} from '../../../../redux';

import { routes } from '../../../../routes';

interface SettingsProps {
  toggleMode: () => void;
}

export const Settings: FC<SettingsProps> = (props: SettingsProps): JSX.Element => {
  const { toggleMode } = props;
  const [ opened, setOpened ] = useState('');
  const [ mailReqReady, setMailReqReady ] = useState<boolean>(true);
  const [ passReqReady, setPassReqReady ] = useState<boolean>(true);
  const [ dateFormat, setDateFormat ] = useLocalStorage('dateFormat', DateFormat.DdMmYyyy);
  const history: History<LocationState> = useHistory();
  const user: UserShort = useSelector(getUserInfo);
  const dispatch = useDispatch();
  let mailReadyTimeout = 0;
  let passReadyTimeout = 0;

  const logout = (): void => {
    localStorage.removeItem('token');

    dispatch(resetFolderListAction());
    dispatch(resetFolderItemsListAction());
    dispatch(resetUserAction());

    history.push(routes.login);
  };

  const closeModal = (key: string): void => {
    if (opened.includes(key)) {
      setOpened('');
    }
  };

  const requestNewPassword = async (): Promise<void> => {
    if (!passReqReady) return;

    try {
      setPassReqReady(false);

      await dispatch(resetPasswordRequestThunk(user?.email));
      toast.info('Check your inbox. We sent you email with further instructions.');

      passReadyTimeout = setTimeout(() => {
        setPassReqReady(true);
      }, 2000) as unknown as number;
    } catch (e: unknown) {
      toast.error('Error occurred during password reset request');
    }
  };

  const requestNewEmail = async (): Promise<void> => {
    if (!mailReqReady) return;

    try {
      setMailReqReady(false);

      await dispatch(changeEmailRequestThunk());
      toast.info('To confirm changing, please check out your inbox.');

      mailReadyTimeout = setTimeout((): void => {
        setMailReqReady(true);
      }, 2000) as unknown as number;
    } catch (e: unknown) {
      toast.error('Error occurred during email change request');
    }
  };

  const toggleDateFormat = (): void => {
    if (dateFormat === DateFormat.DdMmYyyy) {
      setDateFormat(DateFormat.MmDdYyyy);
    } else {
      setDateFormat(DateFormat.DdMmYyyy);
    }
  };

  useEffect((): void => {
    if (dateFormat !== DateFormat.MmDdYyyy && dateFormat !== DateFormat.DdMmYyyy) {
      setDateFormat(DateFormat.MmDdYyyy);
    }
  }, [ dateFormat ]);

  useEffect(() => {
    return (): void => {
      clearTimeout(mailReadyTimeout);
      clearTimeout(passReadyTimeout);
    };
  });

  return (
    <aside className={ `page-sidebar` }>
      <div className="page-sidebar-top">
        <Button onClick={ toggleMode }
                className="page-sidebar-top__back-button"
                buttonType={ ButtonType.Transparent }
                value="Settings" />
        <Button value="Log Out"
                onClick={ logout }
                buttonType={ ButtonType.Gray } />
      </div>
      <div className="page-sidebar-settings">
        <SettingsEntry title="Click to change nickname"
                       value={ user?.name || 'nickname' }
                       onClick={ () => setOpened('nickname') } />
        <SettingsEntry title="Click to change email"
                       ready={ mailReqReady }
                       value={ user?.email || 'email' }
                       onClick={ requestNewEmail } />
        <SettingsEntry title="Click to change password"
                       ready={ passReqReady }
                       value="Password"
                       onClick={ requestNewPassword } />
        <SettingsEntry title="Click to change date format"
                       value={ `Date: ${ dateFormat }` }
                       onClick={ toggleDateFormat } />
      </div>
      <ModalContainer isOpen={ opened === 'nickname' }
                      closeModal={ closeModal }
                      title="Change nickname">
        <RenameForm oldName={ user?.name || '' }
                    nameSchema={
                      Joi.string()
                        .required()
                        .max(50)
                        .message("Username should be at less than 50 characters")
                        .regex(/^\s*$/, { invert: true })
                        .message('Username cannot be whitespace only')
                        .messages({
                          'string.empty': 'Username cannot be empty',
                        })
                    }
                    formDone={ (newName: string): void => {
                      dispatch(updateUserNicknameAction(newName));
                      closeModal('nickname');
                    } } />
      </ModalContainer>
    </aside>
  );
};

export default Settings;
