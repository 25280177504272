import axios, { AxiosResponse } from 'axios';

// Models
import {
  Response,
  AuthRequest,
  AuthResponse,
  RegisterRequest,
  ChangeEmailRequest,
  ChangePasswordRequest, UserShort,
} from '../app/models';

export const login = async (data: AuthRequest): Promise<Response<AuthResponse>> => {
  const response: AxiosResponse<Response<AuthResponse>> = await axios.post(`/api/auth/login/`, data);
  return response.data;
};

export const createUser = async (data: RegisterRequest): Promise<Response<AuthResponse>> => {
  const response: AxiosResponse<Response<AuthResponse>> = await axios.post(`/api/auth/register/`, data);
  return response.data;
};

export const me = async (): Promise<Response<AuthResponse>> => {
  const response: AxiosResponse<Response<AuthResponse>> = await axios.get(`/api/auth/me/`);
  return response.data;
};

export const updateUserNickname = async (nickname: string): Promise<Response<AuthResponse>> => {
  const response: AxiosResponse<Response<AuthResponse>> = await axios.put(`/api/user/update-profile/`, {
    name: nickname,
  }, { // TODO: move this progress to the file upload
      onUploadProgress: (progressEvent: ProgressEvent) => console.log(progressEvent)
  });
  return response.data;
};

export const resetPasswordRequest = async (email: string): Promise<AxiosResponse> => {
  return await axios.post(`/api/user/change-password`, {
    email: email,
  });
};

export const changeEmailRequest = async (): Promise<AxiosResponse> => {
  return await axios.get(`/api/user/change-email`);
};

export const resetPassword = async (form: ChangePasswordRequest): Promise<AxiosResponse> => {
  return await axios.post(`/api/user/update-password`, form);
};

export const changeEmail = async (form: ChangeEmailRequest): Promise<AxiosResponse> => {
  return await axios.post(`/api/user/update-email`, form);
};

export const socialAuthRequest = async (accessToken: string, provider: string): Promise<Response<UserShort>> => {
  const response: AxiosResponse<Response<UserShort>> = await axios.post(`/api/auth/social/`, {
    accessToken,
    provider,
  });

  return response?.data;
};
