import React, { MutableRefObject, ReactElement, useRef } from 'react';

interface DropdownButtonProps {
  children: ReactElement,
  toggleList?: () => void;
  setPos?: (pos: DOMRect | undefined) => void;
  props?: never,
}

export const DropdownButtonWrapper = (props: DropdownButtonProps): JSX.Element => {
  const { children, toggleList, setPos } = props;
  const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement>(null);

  return React.cloneElement(children, {
    ...children.props,
    ref: buttonRef,
    onClick: (): void => {
      if (toggleList && setPos) {
        toggleList();
        setPos(buttonRef?.current?.getBoundingClientRect());
      }

      if (children.props?.onClick) {
        children.props?.onClick();
      }
    },
  });
};

export default DropdownButtonWrapper;
