// User & Auth
export const UPDATE_USER = 'UPDATE_USER';
export const RESET_USER = 'RESET_USER';
export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const CHANGE_USER = 'CHANGE_USER';

// File
export const UPATE_FILE = 'UPATE_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const FILE_INFO_UPDATE = 'FILE_INFO_UPDATE';
export const UPDATE_SHORT_URL = 'UPDATE_SHORT_URL';

// Folders
export const UPDATE_FOLDERS_LIST = 'UPDATE_FOLDERS_LIST';
export const SELECT_FOLDER = 'SELECT_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const REMOVE_FOLDER = 'REMOVE_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';

// History
export const TOGGLE_HISTORY_ITEM = 'TOGGLE_HISTORY_ITEM';
export const CLEAR_ALL_HISTORY_ITEMS = 'CLEAR_ALL_HISTORY_ITEMS';
export const SELECT_ALL_HISTORY_ITEMS = 'SELECT_ALL_HISTORY_ITEMS';
export const UPDATE_HISTORY_LIST = 'UPDATE_HISTORY_LIST';

// List
export const RESET_LIST = 'RESET_LIST';
export const UPDATE_LIST = 'UPDATE_LIST';
export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const CLEAR_ALL_ITEMS = 'CLEAR_ALL_ITEMS';
export const SELECT_ALL_ITEMS = 'SELECT_ALL_ITEMS';
export const UPLOAD_FILE_STARTED = 'UPLOAD_FILE_STARTED';
export const UPLOAD_FILE_FINISHED = 'UPLOAD_FILE_FINISHED';
export const PROCESS = 'PROCESSING';
