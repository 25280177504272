import axios from 'axios';

const AUTH_TOKEN: string | null = localStorage.getItem('token');
export const BASE_URL = process.env.REACT_APP_API_KEY;

export const axiousDefaults = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${ AUTH_TOKEN }`,
  BaseUrl: BASE_URL || 'http://localhost:8000',
};

export const ApplyAxiousDefaults = (): void => {
  axios.defaults.baseURL = axiousDefaults.BaseUrl;
  axios.defaults.headers.post['Content-Type'] = axiousDefaults['Content-Type'];
};

export default axiousDefaults;
