import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { OutputSelector } from '@reduxjs/toolkit';
import { History, Location, LocationState } from 'history';
import ReactPaginate from 'react-paginate';

// Hooks
import { useQuery } from '../../../hooks';

// Models
import { Pagination } from '../../../models';

// Selectors
import { GlobalReduxState } from '../../../../redux';

// Redux
import { useSelector } from 'react-redux';

interface PaginationProps {
  paginationQuery: OutputSelector<GlobalReduxState, Pagination, (res: GlobalReduxState) => Pagination>;
}

export const PaginationElement: FC<PaginationProps> = (props: PaginationProps): JSX.Element => {
  const { paginationQuery } = props;
  const history: History<LocationState> = useHistory();
  const location: Location = useLocation();
  const pagination: Pagination = useSelector(paginationQuery);
  const [ page, setPage ] = useState(0);
  const query: URLSearchParams = useQuery();

  const getShownItemsCount = (): number => {
    let result: number = pagination.currentPage * pagination.itemsPerPage;

    if (pagination.itemsPerPage > pagination.totalItems || result > pagination.totalItems) {
      result = pagination.totalItems;
    }

    return result;
  };

  const getStartItemNumber = (): number => {
    let result: number = pagination.currentPage * pagination.itemsPerPage - pagination.itemsPerPage + 1;

    if (result > pagination.totalItems) {
      result = 0;
    }

    return result;
  };

  const setPaginationPage = (status: { selected: number }): void => {
    if (pagination.currentPage !== 0) {
      const querySearch = query.get('search');
      const search: string = querySearch ? `&search=${ querySearch.toString() }` : '';

      history.push({
        pathname: location.pathname,
        search: `page=${ status.selected + 1 }${ search }`,
      });
    }
  };

  useEffect((): void => {
    const queryPage: string | null = query.get('page');

    if (pagination?.currentPage >= 1) {
      setPage(pagination.currentPage - 1);
    } else {
      if (queryPage) {
        setPage(+queryPage - 1);
      } else {
        setPage(0);
      }
    }
  }, [ pagination.currentPage ]);

  return (
    <div className="pagination-wrapper">
      <h4 className="pagination-info">
        { `Shown ${ getStartItemNumber() } - ${ getShownItemsCount() } out of ${ pagination.totalItems }` }
      </h4>
      <ReactPaginate previousLabel="Prev"
                     nextLabel="Next"
                     breakLabel="..."
                     previousClassName="pagination__trigger pagination__previous"
                     nextClassName="pagination__trigger  pagination__next"
                     pageLinkClassName="pagination__trigger pagination__page"
                     breakClassName="pagination__trigger pagination__breaker"
                     activeClassName="pagination__active"
                     initialPage={ page }
                     forcePage={ page }
                     pageCount={ pagination.totalPages }
                     marginPagesDisplayed={ 1 }
                     pageRangeDisplayed={ 3 }
                     onPageChange={ setPaginationPage }
                     containerClassName="pagination"
      />
    </div>
  );
};

export default PaginationElement;
