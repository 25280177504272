import React, { FC } from 'react';

import { Button, ButtonType } from '../../../Button';

export interface DropdownElementProps {
  className?: string;
  children?: string;
  value?: string;
  onClick?: () => void;
}

export const DropdownElement: FC<DropdownElementProps> = (props: DropdownElementProps): JSX.Element => {
  const { children, onClick, value, className } = props;

  return (
    <li className={ `dropdown-list-item${ className ? ' ' + className : '' }` }>
      <Button buttonType={ ButtonType.ListButton }
              className="dropdown-list-item__button"
              onClick={ onClick }
      >
        { value || children || '' }
      </Button>
    </li>
  );
};

export default DropdownElement;
