import { AnyAction, Draft } from '@reduxjs/toolkit';

// Constants
import {
  RESET_LIST,
  TOGGLE_ITEM,
  UPDATE_LIST,
  CLEAR_ALL_ITEMS,
  SELECT_ALL_ITEMS,
  UPLOAD_FILE_STARTED,
  UPLOAD_FILE_FINISHED,
} from '../constants';

// Models
import { ListState } from '../../app/models';

import {
  stateToggleItem,
  stateGetAllItemsHashes,
} from './reducer-methods';

const defaultState: ListState = {
  selected: [],
  items: [],
  pagination: {
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 0,
  },
  uploading: false,
  loaded: false,
};
const listObject: ListState = defaultState;

export const list = (state: Draft<ListState> = listObject, action: AnyAction): ListState => {
  switch (action.type) {
    case SELECT_ALL_ITEMS:
      return {
        ...(state as ListState),
        selected: stateGetAllItemsHashes(state as ListState),
      };
    case CLEAR_ALL_ITEMS:
      return {
        ...(state as ListState),
        selected: [],
      };
    case TOGGLE_ITEM:
      return {
        ...(state as ListState),
        selected: stateToggleItem(state as ListState, action.payload),
      };
    case `${ UPDATE_LIST }_FULFILLED`:
      return {
        ...(state as ListState),
        selected: [],
        items: action.payload.files,
        pagination: action.payload.pagination,
        loaded: true,
      };
    case UPLOAD_FILE_STARTED:
      return {
        ...(state as ListState),
        uploading: true,
      };
    case UPLOAD_FILE_FINISHED:
      return {
        ...(state as ListState),
        uploading: false,
      };
    case RESET_LIST:
      return { ...defaultState };
    default:
      return state;
  }
};
