import { ObjectSchema } from 'joi';
import { ErrorOption, FieldPath } from 'react-hook-form';

type setErrorType = (name: FieldPath<never>, error: ErrorOption, options?: { shouldFocus: boolean }) => void;

/**
 * Sets the errors from response to Joi form. Should be used only after using "useForm" hook
 * @param scheme Joi form schema
 * @param setError Joi setError hook reference
 */
export const useFormErrors = (scheme: ObjectSchema, setError: setErrorType): (errorsObject: Record<string, Array<string>>) => void => {
  const objectSchema = scheme;

  return (errorsObject: Record<string, Array<string>>): void => {
    const fields: Array<string> = getSchemaKeys(objectSchema);

    for (const field of fields) {
      const errorMessage: string | undefined = getFormErrors(errorsObject, field);
      if (errorMessage !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setError(field, {
          message: errorMessage,
        });
      }
    }
  };
};

const getSchemaKeys = (scheme: ObjectSchema): Array<string> => {
  const schemeKeys: Array<string> = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const schemaFieldEntries = scheme?._ids._byKey.entries();

  for (const fieldEntry of schemaFieldEntries) {
    schemeKeys.push(fieldEntry[0]);
  }

  return schemeKeys;
};

const getFormErrors = (responseErrors: Record<string, Array<string>>, fieldName: string): string | undefined => {
  const keys: Array<string> = Object.keys(responseErrors);

  if (keys.includes(fieldName) && responseErrors[fieldName]?.length > 0) {
    return responseErrors[fieldName][0] || '';
  }
};
