import { AsyncAction } from 'redux-promise-middleware';
import { toast } from 'react-toastify';

import { CHANGE_USER } from '../constants';
import { AuthResponse, Response, UserShort } from '../../app/models';
import { me, updateUserNickname } from '../../request-agent';

export const getUserInfoAction = (): AsyncAction => ({
  type: CHANGE_USER,
  payload: async (): Promise<UserShort> => {
    const response: Response<AuthResponse> = await me();
    return response.data.user;
  },
});

export const updateUserNicknameAction = (nickname: string): AsyncAction => ({
  type: CHANGE_USER,
  payload: async (): Promise<UserShort> => {
    try {
      const user: Response<AuthResponse> = await updateUserNickname(nickname);

      if (user?.success) {
        toast.info('Nickname successfully updated');
      }

      return user.data.user;
    } catch (e: unknown) {
      return {
        id: 0,
        name: 'guest',
        email: '',
      };
    }
  },
});
