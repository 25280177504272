import React, { FC, useEffect } from 'react';

import { FolderItem } from '../../../models';
import { FolderButton } from '../FolderButton';

interface MoveToFolderProps {
  list: Array<FolderItem>;
  onClick?: (folder: FolderItem) => void;
}

export const MoveToFolder: FC<MoveToFolderProps> = (props: MoveToFolderProps): JSX.Element => {
  const { list, onClick } = props;
  const refreshFolderList = (): Array<JSX.Element> => {
    return list.map(
      (folder: FolderItem) => <FolderButton key={ folder.hash }
                                            folder={ folder }
                                            hideManageButtons
                                            onClick={ () => onClick ? onClick(folder) : '' } />
    );
  };
  let foldersList: Array<JSX.Element> = refreshFolderList();

  useEffect((): void => {
    foldersList = refreshFolderList();
  }, [ list ]);

  return (
    <div className="folders-list-modal">
      { foldersList }
    </div>
  );
};

export default MoveToFolder;
