import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

// Components
import { FormTextInput, Button } from '../..';

// Models
import { ChangeNameRequest } from '../../../../models';

interface RenameFormProps {
  alertError?: string;
  oldName: string;
  formDone?: (newName: string) => void;
  nameSchema: Joi.SchemaLike,
}

export const RenameForm: FC<RenameFormProps> = (props: RenameFormProps): JSX.Element => {
  const {
    oldName,
    formDone,
    alertError,
    nameSchema,
  } = props;

  const formScheme: Joi.ObjectSchema<ChangeNameRequest> = Joi.object({
    name: nameSchema,
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ChangeNameRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(formScheme),
    defaultValues: {
      name: oldName,
    },
  });

  const onSubmit = async (): Promise<void> => {
    try {
      if (formDone !== undefined) {
        formDone(getValues('name'));
      }
    }
    catch (error: unknown) {
      toast.error(alertError || 'Rename process occurred an error');
    }
  };

  return (
    <form onSubmit={ handleSubmit(onSubmit) }
          className="change-name-form"
    >
      <FormTextInput type="text"
                     autoFocus
                     register={ register('name') }
                     placeholder="New name"
                     showError={ !!errors.name }
                     error={ errors.name?.message }
      />
      <Button submit
              className="change-name-form__send"
              value="Save"
              disabled={ !isValid || isSubmitting } />
    </form>
  );
};

export default RenameForm;
