import { useEffect, DependencyList } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {DependencyList | undefined} deps - dependency list
 */
export const useKeypress = (key: string, action: () => void, deps?: DependencyList | undefined): void => {
  useEffect(() => {
    function onKeyup(e: { key: string; }): void {
      if (e.key === key) action();
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, deps || []);
};
