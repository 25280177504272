import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { History } from 'history';

import { cases, keysToCase } from '../utils';

import routes from '../routes';

export const ApplyInterceptors = (history: History, strict = true): void => {
  axios.interceptors.request.use((request: AxiosRequestConfig): AxiosRequestConfig => {
    if (localStorage.getItem('token')) {
      const token: string | null = localStorage.getItem('token');
      request.headers.Authorization = `Bearer ${ token }`;
    }

    const newRequest: AxiosRequestConfig = request;

    if (request.data) {
      newRequest.data = keysToCase(newRequest.data, cases.snake);
    }

    return newRequest;
  }, (error) => Promise.reject(error));

  axios.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
    const convertedResponse: AxiosResponse = response;

    convertedResponse.data = keysToCase(convertedResponse.data, cases.camel);
    return convertedResponse;
  }, (error) => {
    const newError = error;

    if (newError?.response?.data) {
      newError.response.data = keysToCase(error.response.data, cases.camel);
    }

    if (strict) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        history.push(routes.login);
      }
    }

    if (error?.response?.status === 502 || error?.response?.status === 500) {
      toast.error('Server side error');
    }

    return Promise.reject(newError || error);
  });
};

export default ApplyInterceptors;
