import { PayloadAction, Action } from '@reduxjs/toolkit';
import { AsyncAction } from 'redux-promise-middleware';

// Constants
import {
  TOGGLE_HISTORY_ITEM,
  UPDATE_HISTORY_LIST,
  CLEAR_ALL_HISTORY_ITEMS,
  SELECT_ALL_HISTORY_ITEMS
} from '../constants';

// Models
import { Response, FolderItemResponse } from '../../app/models';

// Requests
import { getHistoryListInfo } from '../../request-agent/history';

export const toggleHistoryItem = (folderHash: string): PayloadAction<string> => ({
  payload: folderHash,
  type: TOGGLE_HISTORY_ITEM,
});

export const clearAllHistoryItems = (): Action => ({
  type: CLEAR_ALL_HISTORY_ITEMS,
});

export const selectAllHistoryItems = (): Action => ({
  type: SELECT_ALL_HISTORY_ITEMS,
});

export const fetchHistoryItemsList = (): AsyncAction => ({
  type: UPDATE_HISTORY_LIST,
  payload: async (): Promise<FolderItemResponse> => {
    const result: Response<FolderItemResponse> = await getHistoryListInfo();

    return result?.data;
  },
});
