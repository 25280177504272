import { RefObject, useCallback, useEffect, useState } from 'react';

// Hooks
import { useEventListener } from './use-event-listener';

interface Size {
  width: number;
  height: number;
}

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(elementRef: RefObject<T>): Size => {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const updateSize = useCallback((): void => {
    const node = elementRef?.current;
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      });
    }
  }, [elementRef]);

  // Initial size on mount
  useEffect((): void => {
    updateSize();
  }, []);

  useEventListener('resize', updateSize);

  return size;
};

export default useElementSize;
