import Joi from 'joi';

export const oneNumberReg = /(?=.*\d)/;
export const oneLowercaseReg = /(?=.*[a-z])/;
export const oneSpecialReg = /(?=.*\W)/;
export const noSpaceReg = /[^-\s]/;

export const passwordStringField: Joi.StringSchema = Joi.string()
  .required()
  .min(8).message('8 characters minimum required')
  .max(16).message('16 characters maximum required')
  .pattern(oneSpecialReg, 'oneSpec').message('One special character required')
  .pattern(oneNumberReg, 'oneNumber').message('One number required')
  .pattern(oneLowercaseReg, 'oneLower').message('One lowercase character required')
  .pattern(noSpaceReg, 'noSpace').message('Must not contain spaces')
  .messages({
    'string.empty': 'Password field cannot be empty',
  });

export interface PasswordValidation {
  oneLowercase: boolean;
  oneSpecial: boolean;
  minEight: boolean;
  oneNumber: boolean;
}

export const validatePassword = (password?: string): PasswordValidation => {
  const result: PasswordValidation = {
    oneLowercase: false,
    oneSpecial: false,
    minEight: false,
    oneNumber: false,
  };

  if (password) {
    if (password?.length >= 8) {
      result.minEight = true;
    }

    if (new RegExp(oneNumberReg).test(password)) {
      result.oneNumber = true;
    }

    if (new RegExp(oneLowercaseReg).test(password)) {
      result.oneLowercase = true;
    }

    if (new RegExp(oneSpecialReg).test(password) && new RegExp(noSpaceReg).test(password)) {
      result.oneSpecial = true;
    }
  }

  return result;
};
